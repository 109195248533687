
import Vue from "vue";
import Component from "vue-class-component";
import { Auth } from "@/store/modules";

@Component({ 
  name: "Login",
  metaInfo: { 
    title: 'Sign In | SkillToon - Master New Skills with Live Interactive Workshops',
    link: [
      { rel: 'canonical', href: 'https://skilltoon.com/login' }
    ],
    meta: [
      { property: 'og:url', content: 'https://skilltoon.com/login' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Sign In | SkillToon - New Way of Teaching & Learning Skills' },
      { property: 'og:description', content: 'Sign in at SkillToon to learn, create, acquire and teach skills in your area of passion at home. Explore today!' },
      { property: 'og:image', content: 'https://app-dev.skilltoon.com/logo-color.svg' },
      { name: 'description', content: 'Sign In at SkillToon to learn, create, acquire and teach skills in your area of interest at home. Explore today!' },
      { name: 'keywords', content: 'SkillToon' },
      { name: 'robots', content: 'index,follow' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: 'https://skilltoon.com/login' },
      { property: 'twitter:title', content: 'Sign In | SkillToon - New Way of Teaching & Learning Skills' },
      { property: 'twitter:description', content: 'Sign in at SkillToon to learn, create, acquire and teach skills in your area of passion at home. Explore today!' },
      { property: 'twitter:image', content: 'https://app-dev.skilltoon.com/logo-color.svg' },
    ]
  }
})
export default class extends Vue {
  get authError() {
    return !!this.$route.query.authError;
  }

  get redirectUrl() {
    const query = this.$route.query.redirectUrl;
    return typeof query === 'string' ? query : undefined;
  }

  async googleSignUp () {
    await Auth.googleSignUp({ redirectUrl: this.redirectUrl });
  }

  async facebookSignUp () {
    await Auth.facebookSignUp({ redirectUrl: this.redirectUrl });
  }
}
